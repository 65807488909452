
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import store from "@/store";

export default defineComponent({
  name: "CZPositionSummary",

  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Position Summary", ["Flexible Procurement"]);

      getPositionSummary();
    });
    const loading = ref<boolean>(false);

    watch(
      () => [
        store.getters.layoutConfig("flexibleProcurement.scenarioName"),
        store.getters.layoutConfig("flexibleProcurement.reportName"),
        store.getters.layoutConfig("flexibleProcurement.current"),
      ],
      function () {
        getPositionSummary();
      }
    );

    const chartOptions = {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          columnWidth: 25 + "%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["MTM Cost", "Market Cost", "Budget Cost"],
      },
      yaxis: {
        title: {
          text: "£",
          style: {
            fontSize: "15px",
          },
        },
      },
      legend: {
        show: true,
        floating: false,
        fontSize: "20px",
      },
    };

    const series = reactive([{}]);
    series.splice(0);
    /**
     *@description For getting position summary report
     */
    function getPositionSummary() {
      loading.value = true;

      let AccountId = store.getters.AccountId;
      let current = store.getters.layoutConfig("flexibleProcurement.current");
      let FPreportName = store.getters.layoutConfig(
        "flexibleProcurement.reportName"
      );
      let FPscenarioName = store.getters.layoutConfig(
        "flexibleProcurement.scenarioName"
      );

      const accountInfo: any = {
        clientId: AccountId,
        productId: 4,
        reportId: FPreportName,
        scenarioId: FPscenarioName,
        iscurrent: current,
      };
      ApiService.post("api/FlexProcurement/getFlexProcrement", accountInfo)
        .then(async ({ data }) => {
          const response = await data;
          series.splice(0);
          //alert(JSON.stringify(response))
          //Makeing the series with dynamic values
          const datas = {
            data: [
              response.costSummary.mtm,
              response.costSummary.market,
              response.costSummary.budget,
            ],
          };
          series.push(datas);

          loading.value = false;
        })
        .catch((a) => {
          loading.value = false;
          console.log("Api Log Error", a);
        });
    }
    //alert(JSON.stringify(series))
   // alert(JSON.stringify(chartOptions))
    return {
      series,
      chartOptions,
      loading,
    };
  },
});
